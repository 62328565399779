<template>
   <div class="main">
       <div class="logo">
          <img class="logo-img" src="../static/image/logo.png"/>
          <div class="logo-name">
              <p>萌动乐园</p>
          </div>
       </div>
       <div class="page-one">
           <div class="page-1-gb">
               <img class="one-left-1" src="../static/image/1-左1.png">
               <img class="one-right-1" src="../static/image/1-右1.png">
           </div>
           <div class="page-1-center">
               <img src="../static/image/中心图-1.png">
           </div>
       </div>
       <div class="page-one">
           <div class="page-1-gb">
               <img  class="one-right-1" src="../static/image/2-左.png">
               <img class="one-left-1" src="../static/image/2-右.png">
           </div>
           <div class="page-1-center">
               <img src="../static/image/中心图-2.png">
           </div>
       </div>
       <div class="page-one">
           <div class="page-1-gb">
               <img  class="one-left-1" src="../static/image/3-左.png">
               <img class="one-right-1" src="../static/image/3-右.png">
           </div>
           <div class="page-1-center">
               <img src="../static/image/中心图-3.png">
           </div>
       </div>
       <div class="page-one">
           <div class="page-1-gb">
               <img  class="one-right-1" src="../static/image/4-左.png">
               <img class="one-left-1" src="../static/image/4-右.png">
           </div>
           <div class="page-1-center">
               <img src="../static/image/中心图-4.png">
           </div>
       </div>
       <div class="c-info-main">
          <div class="c-info">
              <div>陕ICP备2021016040号-1</div>
              <div>家长监护 本公司游戏产品适合18岁以上成年人使用</div>
          </div>
       </div>
   </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
.page-one{
    width: 100%;
    height: 100%;
}
.page-1-gb{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    z-index: -99999;
    position: absolute;
    height: 100%;
}
.page-1-center{
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.logo{
    position: fixed;
    left: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
}
.logo-img{
    width: 20px;
    height: 20px;
    margin: 5px;
}
.logo-name{
    display: flex;
    display: -webkit-flex;
    text-align: center;
    align-items: center;
    color: #282828;
    font-size: 5px;
    font-weight: 600;
}
.c-info-main{
    width: 100%;
    height: 10%;
    background-color: #333333;
    color: white;
}
.c-info{
    display: flex;
    display: -webkit-flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
@media screen and  (max-width: 576px) {
  .one-left-1{
      width: 100px;
  }
  .one-right-1{
      width: 250px;
  }
  .page-one{
      height: 245px;
  }
  .page-1-gb{
     height: 245px;
  }

  .page-1-center img{
      width: 198px;
      height: 143px;
  }

  .logo-img{
      width: 40px;
      height: 40px;
      margin: 10px;
  }
  .logo-name{
      font-size: 10px;
  }

}

</style>
